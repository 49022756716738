import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(config);

firebase
  .auth()
  .setPersistence(firebase.auth.Auth.Persistence.NONE)
  .then(() => {
    //
  })
  .catch((error) => {
    console.log(error.code);
    console.log(error.message);
  });

export const db = firebase.firestore();

const storage = firebase.storage();
storage.setMaxUploadRetryTime(2000);
export const storageRef = storage.ref();

export const analytics = firebase.analytics();

export default firebase;
