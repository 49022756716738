var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-2 pa-sm-4",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-4",staticStyle:{"height":"60px"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"align-self":"center"}},[_c('span',{staticClass:"text-subtitle-1 text-sm-h6"},[_vm._v("探知機一覧")])]),_c('v-col',{staticClass:"text-end",attrs:{"align-self":"center"}},[_c('v-btn',{staticClass:"white",attrs:{"color":"primary","icon":"","elevation":"2","large":_vm.$vuetify.breakpoint.smAndUp},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.editDialog.openDialog()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"loading":_vm.loading,"loading-text":"読込中","no-data-text":"データがありません","sort-by":"machineName","disable-sort":_vm.$vuetify.breakpoint.xs,"fixed-header":"","hide-default-footer":"","height":_vm.getTableHeight,"dense":""},scopedSlots:_vm._u([{key:"item.remarks",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.remarks))]),(item.imgFileURL)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.dialogImg.openDialog({
                  text: ("機械名：" + (item.machineName)),
                  url: item.imgFileURL,
                })}}},[_c('v-icon',[_vm._v("mdi-image-outline")])],1):_vm._e()]}},{key:"item.testPieces",fn:function(ref){
                var item = ref.item;
return _vm._l((item.testPieces),function(testPiece,index){return _c('div',{key:index},[_c('span',[_vm._v(_vm._s(testPiece.name))])])})}},{key:"item.size",fn:function(ref){
                var item = ref.item;
return _vm._l((item.testPieces),function(testPiece,index){return _c('div',{key:index},[_c('span',[_vm._v("φ "+_vm._s(testPiece.size.toFixed(1))+" mm")])])})}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.editDialog.openDialog(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.deleteDialog.openDialog(item, item.machineName)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}},{key:"top",fn:function(){return [_c('DialogDetectorEdit',{ref:"editDialog",attrs:{"detectors":_vm.items,"storeItem":_vm.storeItem}}),_c('DialogDocDelete',{ref:"deleteDialog",attrs:{"target":"探知機","removeItem":_vm.removeItem}})]},proxy:true}],null,true)})],1)],1)],1),_c('DialogImg',{ref:"dialogImg"}),_c('DialogSendError',{ref:"sendErrorDialog"}),_c('DialogMessage',{attrs:{"dialog":_vm.messageDialog,"message":_vm.message},on:{"close":function($event){_vm.messageDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }