<template>
  <div>
    <v-overlay :value="menu"></v-overlay>
    <v-menu
      v-model="menu"
      transition="slide-x-reverse-transition"
      :close-on-click="false"
      :close-on-content-click="false"
      :min-width="$vuetify.breakpoint.xs ? 350 : 500"
      :max-width="$vuetify.breakpoint.xs ? 350 : 500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="white"
          v-bind="attrs"
          v-on="on"
          color="primary"
          icon
          elevation="2"
          :large="$vuetify.breakpoint.smAndUp"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card class="pa-2 pa-sm-4">
        <v-card-title class="pa-4">
          <span class="text-subtitle-1 text-sm-h6">製品一覧</span>
          <v-spacer></v-spacer>
          <v-btn @click.stop="closeMenu()" class="px-0" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-3 pb-4">
          <v-text-field
            :class="$vuetify.breakpoint.xs ? 'input--xs text-body-2' : ''"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            placeholder="製品を検索"
            clearable
            hide-details
            dense
          />
        </v-card-text>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          :items-per-page="-1"
          item-key="productName"
          sort-by="productName"
          :search="search"
          no-data-text="製品がありません"
          no-results-text="製品が見つかりません"
          show-select
          fixed-header
          hide-default-footer
          :mobile-breakpoint="null"
          height="400"
          dense
          @click:row="(_, row) => row.select(!row.isSelected)"
        >
          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              v-bind="props"
              v-on="on"
              color="primary"
              :ripple="false"
              :value="props.indeterminate ? props.indeterminate : props.value"
              :indeterminate="!!selected.length && selected.length < items.length"
            />
          </template>
          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox
              color="primary"
              :ripple="false"
              :value="isSelected"
              @input="select($event)"
            />
          </template>
          <template v-slot:[`item.productName`]="{ item }">
            <div style="min-width: 150px">{{ item.productName }}</div>
          </template>
          <template v-slot:[`item.start`]="{ item }">
            <span style="padding-right: 18px">{{ item.start }}</span>
          </template>
          <template v-slot:[`item.elapsed`]="{ item }">
            <span style="padding-right: 18px">{{ item.elapsed }}</span>
          </template>
          <template v-slot:[`item.end`]="{ item }">
            <span style="padding-right: 18px">{{ item.end }}</span>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <span class="px-2 text-body-2 text--secondary">
            {{ selected.length ? `${selected.length}件選択中` : "選択されていません" }}
          </span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :disabled="!selected.length" @click="addItem()">
            リストに追加
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";

export default {
  props: {
    items: Array,
  },
  mixins: [calcDate],
  data: () => ({
    menu: false,
    search: "",
    selected: [],
    headers: [
      { text: "製品名", value: "productName" },
      { text: "開始", value: "start", width: 58, align: "center" },
      { text: "経過", value: "elapsed", width: 58, align: "center" },
      { text: "終了", value: "end", width: 62, align: "center" },
    ],
  }),
  methods: {
    /**
     * メニューを閉じる
     */
    closeMenu() {
      this.menu = false;
      this.selected = [];
    },

    /**
     * リストに追加
     */
    addItem() {
      this.$emit("select", this.selected);
      this.closeMenu();
    },
  },
};
</script>

<style scoped>
::v-deep .v-input__control .v-input__slot .v-input__prepend-inner {
  padding-right: 16px;
}

.input--xs ::v-deep .v-input__prepend-inner {
  padding-right: 12px;
}

.input--xs ::v-deep .v-text-field.v-input--dense:not(.v-text-field--outlined) input::placeholder {
  font-size: 14px;
}

.v-data-table ::v-deep .v-data-table__wrapper table thead tr th {
  white-space: pre-wrap;
}

.v-data-table ::v-deep .v-data-table__wrapper table thead tr th {
  padding: 0 8px;
  height: 42px !important;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
}

.v-data-table ::v-deep .v-data-table__wrapper table thead tr th:first-child,
.v-data-table ::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 12px !important;
  padding-right: 8px !important;
}

.v-data-table ::v-deep .v-data-table__wrapper table thead tr th:last-child,
.v-data-table ::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 12px !important;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr.v-data-table__selected {
  background-color: initial;
}

::v-deep .v-input--selection-controls__input {
  margin: 0 !important;
}
</style>
