var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white",attrs:{"color":"primary","icon":"","elevation":"2","large":_vm.$vuetify.breakpoint.smAndUp}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-table-eye")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-2 pa-sm-4",attrs:{"color":"#f5f5f5"}},[_c('v-card-title',{staticClass:"pa-4 d-flex align-baseline"},[_c('span',{staticClass:"mr-5 text-subtitle-1 text-sm-h6"},[_vm._v("点検状況確認")]),_c('span',{staticClass:"text-caption text-sm-subtitle-1 text--secondary"},[_vm._v(_vm._s(_vm.date))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.items.length == 0)?_c('v-card-text',{staticClass:"px-4 pb-0",staticStyle:{"height":"50vh"}},[_vm._v(" 探知機情報がありません ")]):_c('v-tabs',{attrs:{"center-active":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(machine){return _c('v-tab',{key:machine.machineName},[_vm._v(" "+_vm._s(machine.machineName)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(machine){return _c('v-tab-item',{key:machine.machineName},[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":""}},[_c('div',{staticClass:"px-2 pt-2"},[(machine.remarks || machine.imgFileURL)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-caption text--secondary",staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(machine.remarks))]),(machine.imgFileURL)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.dialogImg.openDialog({
                    text: ("機械名：" + (machine.machineName) + "\n備考：" + (machine.remarks)),
                    url: machine.imgFileURL,
                  })}}},[_c('v-icon',[_vm._v("mdi-image-outline")])],1):_vm._e()],1):_vm._e(),_c('span',{staticClass:"mr-3 text-body-2"},[_vm._v("テストピース")]),(_vm.$vuetify.breakpoint.xs)?_c('br'):_vm._e(),_vm._l((_vm.detectors[_vm.tab].testPieces),function(testPiece){return _c('span',{key:testPiece.name,staticClass:"mr-3 text-caption"},[_vm._v(" "+_vm._s(testPiece.name)+"：φ "+_vm._s(testPiece.size.toFixed(1))+" mm ")])})],2),_c('v-data-table',{attrs:{"headers":_vm.$vuetify.breakpoint.xs ? _vm.xsHeaders : _vm.headers,"items":machine.results,"items-per-page":-1,"sort-by":"productName","no-data-text":"データがありません。","fixed-header":"","hide-default-footer":"","mobile-breakpoint":null,"dense":"","height":"50vh"},scopedSlots:_vm._u([{key:"item.productName",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"150px"}},[_vm._v(_vm._s(item.productName))])]}}],null,true)})],1)],1)}),1)],1),_c('DialogImg',{ref:"dialogImg"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }