<template>
  <div>
    <div class="px-4 pb-4 text-caption text-sm-body-2">
      <div class="d-flex">
        <div>
          <p class="d-flex">
            <span class="text-no-wrap">探知機名：</span>
            <span>{{ result.machineName }}</span>
          </p>
          <p class="d-flex">
            <span class="text-no-wrap">テストピース：</span>
            <span
              class="mr-3 text-caption"
              v-for="testPiece in result.checkItems"
              :key="testPiece.result"
            >
              {{ testPiece.testPieceName }} φ{{ testPiece.testPieceSize.toFixed(1) }} mm
            </span>
          </p>
          <p>
            <span>点検結果：</span>
            <span :class="result.isNormalForReport ? '' : 'red--text font-weight-bold'">{{
              result.isNormalForReport ? "OK" : "NG"
            }}</span>
          </p>
        </div>
        <v-spacer></v-spacer>
        <slot />
      </div>
      <p class="d-flex">
        <span class="text-no-wrap">送信日時：</span>
        <span>{{ formatDate(result.sentAt, "YYYY/MM/DD HH:mm") }}（{{ result.sender }}）</span>
      </p>
      <p class="d-flex">
        <span class="text-no-wrap">確認日時：</span>
        <span>
          <span>{{ formatDate(result.confirmedAt, "YYYY/MM/DD HH:mm") }}</span>
          <span v-if="result.confirmerName">（{{ result.confirmerName }}）</span>
        </span>
      </p>
      <p class="d-flex">
        <span class="text-no-wrap">承認日時：</span>
        <span>
          <span>{{ formatDate(result.approvedAt, "YYYY/MM/DD HH:mm") }}</span>
          <span v-if="result.approverName">（{{ result.approverName }}）</span>
        </span>
      </p>
    </div>

    <p class="mb-1 pl-4 text-caption text-sm-body-2">詳細結果</p>
    <v-card class="pa-2 mb-4" outlined>
      <v-data-table
        :headers="headers"
        :items="[result]"
        :items-per-page="-1"
        disable-sort
        hide-default-footer
        dense
      >
        <template v-slot:[`item.checkItems`]="{ item }">
          <div class="py-1 d-flex">
            <div>
              <p
                class="px-2 text-no-wrap"
                v-for="(testPiece, index) in item.checkItems"
                :key="index"
              >
                {{ testPiece.testPieceName }}
              </p>
            </div>
            <div>
              <p
                class="px-2 text-no-wrap"
                :class="testPiece.checkResult == 'NG' ? ngClass : ''"
                v-for="(testPiece, index) in item.checkItems"
                :key="index"
              >
                {{ testPiece.checkResult }}
              </p>
            </div>
          </div>
        </template>
        <template v-slot:[`item.detectionResult`]="{ item }">
          <span :class="item.detectionResult == 'NG' ? ngClass : ''">
            {{ item.detectionResult }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";

export default {
  props: {
    result: Object,
  },
  mixins: [calcDate],
  data: () => ({
    headers: [
      { text: "製品名", value: "productName" },
      { text: "点検タイミング", value: "timing", width: 100, align: "center" },
      { text: "作動確認", value: "checkItems", width: "1%", align: "center" },
      { text: "検出物なし", value: "detectionResult", width: 76, align: "center" },
    ],
    ngClass: "red--text font-weight-bold",
  }),
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0;
}

::v-deep .v-data-table__wrapper table thead tr th {
  padding: 0 8px;
  height: 42px !important;
}
::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
}

.v-data-table ::v-deep .v-data-table__wrapper table .v-data-table-header-mobile {
  display: none;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row {
  min-height: 32px;
  font-size: 12px;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row__header {
  font-weight: 400;
  white-space: nowrap;
}
</style>
