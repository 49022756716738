<template>
  <v-dialog v-model="dialog" persistent @input="openDialog()" max-width="800">
    <v-card class="pa-2 pa-sm-4" color="#f5f5f5">
      <v-card-title class="pa-4 d-flex align-baseline">
        <span class="mr-4 text-subtitle-1 text-sm-h6">
          {{ machineUID == "" ? "探知機登録" : "探知機編集" }}
        </span>
        <span class="red--text text-caption">*必須項目</span>
      </v-card-title>

      <v-form v-model="valid" ref="form">
        <v-row class="pa-4" no-gutters>
          <v-col cols="10" sm="6">
            <v-text-field
              v-model="machineName"
              :rules="[
                required,
                !detectors.some((e) => e.id != machineUID && e.machineName == machineName) ||
                  'すでに登録されています',
              ]"
              dense
            >
              <template slot="label">
                探知機名
                <span class="font-weight-bold red--text">*</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-card class="pa-2 mb-4" outlined>
          <v-data-table
            :headers="headers"
            :items="editedItems"
            :items-per-page="-1"
            fixed-header
            hide-default-footer
            disable-sort
            height="300"
            :mobile-breakpoint="null"
            dense
          >
            <template v-slot:[`header.name`]>
              テストピース名
              <span class="font-weight-bold red--text">*</span>
            </template>
            <template v-slot:[`header.size`]>
              サイズ
              <span class="font-weight-bold red--text">*</span>
            </template>
            <template v-slot:[`header.action`]="{}">
              <v-btn icon @click="addItem()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item, index) in items" :key="index">
                  <td class="py-0">
                    <v-text-field
                      class="text-body-2 pa-0"
                      v-model="item.name"
                      :rules="[required]"
                      single-line
                      hide-details
                      placeholder="必須入力"
                    />
                  </td>
                  <td class="py-0">
                    <v-text-field
                      class="text-end text-body-2 pa-0"
                      v-model="item.size"
                      :rules="[required]"
                      prefix="φ"
                      suffix="mm"
                      type="number"
                      :min="0"
                      single-line
                      hide-details
                      placeholder="必須入力"
                    />
                  </td>
                  <td class="py-0">
                    <div class="d-flex justify-center">
                      <v-btn icon @click="editedItems.splice(index, 1)">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div class="d-flex justify-center grey--text" v-if="items.length == 0">
                      データがありません
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-form>

      <!-- 備考 -->
      <p class="ml-4 mb-1 text-body-2">備考</p>
      <v-card class="d-flex" outlined>
        <v-textarea
          class="text-body-2"
          v-model="remarks"
          placeholder="テキスト入力"
          solo
          flat
          hide-details
          no-resize
          height="100"
        />
        <v-sheet class="d-flex justify-center align-center rounded" width="100" height="100">
          <InputImg
            :text="machineName"
            :url.sync="imgFileURL"
            :compressedFile.sync="compressedFile"
            width="80"
          />
        </v-sheet>
      </v-card>

      <!-- 登録ボタン -->
      <v-card-actions class="pt-4">
        <div class="text-caption text--secondary">
          <p class="mb-0" v-if="updatedAt">更新日：{{ updatedAt }}</p>
          <p class="mb-0" v-if="updaterName">更新者：{{ updaterName }}</p>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn
          color="primary"
          depressed
          :loading="processing"
          :disabled="processing || !valid"
          @click="registDetector()"
        >
          登録
        </v-btn>
      </v-card-actions>
    </v-card>

    <DialogSendError ref="sendErrorDialog" />
  </v-dialog>
</template>

<script>
import firebase, { db } from "../../plugins/firebase";
import moment from "moment";
import uploadStorage from "cumin-common/src/mixins/uploadStorage";

export default {
  props: {
    detectors: Array,
    storeItem: Function,
    updateItem: Function,
  },
  mixins: [uploadStorage],
  data: () => ({
    dialog: false,
    processing: false,
    valid: false,
    required: (value) => !!value || value === 0 || "必須入力です",
    updatedAt: "",
    updaterName: "",
    machineUID: "",
    machineName: "",
    editedItems: [],
    headers: [
      { text: "テストピース名", value: "name", width: "70%" },
      { text: "サイズ", value: "size", width: "30%" },
      { text: "", value: "action", width: 68, align: "center" },
    ],
    remarks: "",
    imgFileURL: "",
    compressedFile: null,
  }),
  methods: {
    /**
     * ダイアログを開く
     * @param {object} selectItem
     */
    openDialog(selectItem) {
      this.dialog = true;
      this.compressedFile = null;

      if (selectItem == undefined) {
        this.$nextTick(() => {
          this.$refs.form.reset();
          this.machineUID = "";
          this.machineName = "";
          this.editedItems = [];
          this.remarks = "";
          this.imgFileURL = "";
          this.updatedAt = "";
          this.updaterName = "";
          this.addItem();
        });
      } else {
        this.machineUID = selectItem.id;
        this.machineName = selectItem.machineName;
        this.editedItems = JSON.parse(JSON.stringify(selectItem.testPieces));
        this.remarks = selectItem.remarks ?? "";
        this.imgFileURL = selectItem.imgFileURL ?? "";
        this.updatedAt = moment(selectItem.updatedAt.seconds * 1000).format("YYYY/MM/DD");
        this.updaterName = selectItem.updaterName;
      }
    },

    /**
     * 点検箇所行の追加
     */
    addItem() {
      this.editedItems.push({
        name: "",
        size: "",
      });
    },

    /**
     * 入力情報をDB登録
     */
    async registDetector() {
      this.processing = true;

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
      const isCreate = !this.machineUID;

      const registId = this.machineUID || db.collection("metalDetectors").doc().id;
      const registData = {
        machineName: this.machineName,
        shopUID: shop.shopUID,
        testPieces: this.editedItems.map((item) => ({
          name: item.name,
          size: Number(item.size),
        })),
        remarks: this.remarks,
        imgFileURL: this.imgFileURL,
        updatedAt: serverTimestamp,
        updaterName: user.name,
      };

      // 備考画像の保存
      if (this.compressedFile) {
        const path = `metalDetectorFile/${shop.shopUID}/${registId}/remarks.jpg`;
        const uploadResult = await this.uploadFile({ path, file: this.compressedFile });

        // 送信失敗の場合
        if (uploadResult.status == "error") {
          const type =
            uploadResult.code === "storage/retry-limit-exceeded" ? "unstable" : "unexpected";
          this.$refs.sendErrorDialog.open(type);
          this.processing = false;
          return;
        }

        registData.imgFileURL = uploadResult.url;
      }

      if (isCreate) registData.createdAt = serverTimestamp;

      const isSuccess = await this.storeItem({ isCreate, docId: registId, data: registData });

      this.processing = false;
      if (isSuccess) this.dialog = false;
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table__wrapper table thead tr th {
  height: 48px !important;
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
}

.v-data-table ::v-deep .v-input__slot:before {
  padding-left: 0px !important;
  padding-right: 8px !important;
  border-style: none !important;
}

::v-deep .v-input input::placeholder {
  color: red !important;
  opacity: 0.5;
}

.v-textarea ::v-deep .v-input__control .v-input__slot {
  padding: 0 16px !important;
}
</style>
