var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.menu}}),_c('v-menu',{attrs:{"transition":"slide-x-reverse-transition","close-on-click":false,"close-on-content-click":false,"min-width":_vm.$vuetify.breakpoint.xs ? 350 : 500,"max-width":_vm.$vuetify.breakpoint.xs ? 350 : 500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white",attrs:{"color":"primary","icon":"","elevation":"2","large":_vm.$vuetify.breakpoint.smAndUp}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"pa-2 pa-sm-4"},[_c('v-card-title',{staticClass:"pa-4"},[_c('span',{staticClass:"text-subtitle-1 text-sm-h6"},[_vm._v("製品一覧")]),_c('v-spacer'),_c('v-btn',{staticClass:"px-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeMenu()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"px-3 pb-4"},[_c('v-text-field',{class:_vm.$vuetify.breakpoint.xs ? 'input--xs text-body-2' : '',attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"製品を検索","clearable":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"item-key":"productName","sort-by":"productName","search":_vm.search,"no-data-text":"製品がありません","no-results-text":"製品が見つかりません","show-select":"","fixed-header":"","hide-default-footer":"","mobile-breakpoint":null,"height":"400","dense":""},on:{"click:row":function (_, row) { return row.select(!row.isSelected); }},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-simple-checkbox',_vm._g(_vm._b({attrs:{"color":"primary","ripple":false,"value":props.indeterminate ? props.indeterminate : props.value,"indeterminate":!!_vm.selected.length && _vm.selected.length < _vm.items.length}},'v-simple-checkbox',props,false),on))]}},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"primary","ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.productName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"150px"}},[_vm._v(_vm._s(item.productName))])]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"padding-right":"18px"}},[_vm._v(_vm._s(item.start))])]}},{key:"item.elapsed",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"padding-right":"18px"}},[_vm._v(_vm._s(item.elapsed))])]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"padding-right":"18px"}},[_vm._v(_vm._s(item.end))])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-divider'),_c('v-card-actions',{staticClass:"px-4"},[_c('span',{staticClass:"px-2 text-body-2 text--secondary"},[_vm._v(" "+_vm._s(_vm.selected.length ? ((_vm.selected.length) + "件選択中") : "選択されていません")+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.selected.length},on:{"click":function($event){return _vm.addItem()}}},[_vm._v(" リストに追加 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }