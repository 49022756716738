<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card class="pa-2 pa-sm-4">
      <v-card-title class="pa-4 d-flex align-baseline">
        <span class="mr-4 text-subtitle-1 text-sm-h6">製品登録</span>
        <span class="red--text text-caption">*必須項目</span>
      </v-card-title>
      <v-card-text class="px-4 pb-4">
        <v-form v-model="valid" ref="form">
          <v-text-field
            v-model="productName"
            :rules="[
              required,
              !products.some((e) => e.id != productUID && e.productName == productName) ||
                'すでに登録されています',
            ]"
          >
            <template slot="label">
              製品名
              <span class="font-weight-bold red--text">*</span>
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div class="text-caption text--secondary">
          <p class="mb-0" v-if="updatedAt">更新日：{{ updatedAt }}</p>
          <p class="mb-0" v-if="updaterName">更新者：{{ updaterName }}</p>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn
          color="primary"
          depressed
          :loading="processing"
          :disabled="processing || !valid"
          @click="registProduct()"
        >
          登録
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase, { db } from "../../plugins/firebase";
import moment from "moment";

export default {
  props: {
    products: Array,
    storeItem: Function,
    updateItem: Function,
  },
  data: () => ({
    dialog: false,
    processing: false,
    valid: false,
    required: (value) => !!value || "必須入力です",
    updatedAt: "",
    updaterName: "",
    productUID: "",
    productName: "",
  }),
  methods: {
    /**
     * ダイアログを開く
     * @param {object} selectItem
     */
    async openDialog(selectItem) {
      this.productUID = selectItem == undefined ? "" : selectItem.id;
      this.productName = selectItem == undefined ? "" : selectItem.productName;
      this.updatedAt =
        selectItem == undefined
          ? ""
          : moment(selectItem.updatedAt.seconds * 1000).format("YYYY/MM/DD");
      this.updaterName = selectItem == undefined ? "" : selectItem.updaterName;
      this.dialog = true;
      if (selectItem == undefined) this.$nextTick(() => this.$refs.form.reset());
    },

    /**
     * 入力情報をDB登録
     */
    async registProduct() {
      this.processing = true;

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
      const isCreate = !this.productUID;

      const registId = this.productUID || db.collection("metalDetectorControlProducts").doc().id;
      const registData = {
        productName: this.productName,
        shopUID: shop.shopUID,
        updatedAt: serverTimestamp,
        updaterName: user.name,
      };

      if (isCreate) registData.createdAt = serverTimestamp;

      const isSuccess = await this.storeItem({ isCreate, docId: registId, data: registData });

      this.processing = false;
      if (isSuccess) this.dialog = false;
    },
  },
};
</script>
