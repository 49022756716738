<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col align-self="center">
        <span class="mr-5 text-subtitle-1 text-sm-h6">異物探知点検表</span>
        <span class="text-caption text-sm-subtitle-1 text--secondary">{{ businessDate }}</span>
      </v-col>
      <v-col class="d-flex justify-end align-center" cols="4">
        <TotalResultDialog :items="detectorResults" :detectors="detectors" :date="businessDate" />
        <MenuProduct
          :items="detectorResults[tab] ? detectorResults[tab].results : []"
          @select="addToList"
        />
      </v-col>
    </v-row>

    <!-- 点検票 -->
    <v-row class="mb-4" no-gutters :style="{ 'min-height': `${getSheetHeight}px` }">
      <v-col class="pa-4" v-if="detectors.length == 0">
        <span class="text-body-2 text--secondary">探知機情報がありません</span>
      </v-col>

      <v-col v-else>
        <!-- タブ部分 -->
        <v-tabs v-model="tab" center-active show-arrows>
          <v-tab v-for="tabItem in tabItems" :key="tabItem.machineName">
            {{ tabItem.machineName }}
          </v-tab>
        </v-tabs>

        <!-- タブ内容 -->
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="tabItem in tabItems" :key="tabItem.machineName">
            <v-card>
              <v-sheet class="px-4 pt-4" color="#f8f8f8">
                <div
                  v-if="tabItem.remarks || tabItem.imgFileURL"
                  class="d-flex align-center"
                  style="min-height: 36px"
                >
                  <span class="text-caption text--secondary" style="white-space: pre-wrap">{{
                    tabItem.remarks
                  }}</span>
                  <v-btn
                    v-if="tabItem.imgFileURL"
                    icon
                    @click.stop="
                      $refs.dialogImg.openDialog({
                        text: `機械名：${tabItem.machineName}\n備考：${tabItem.remarks}`,
                        url: tabItem.imgFileURL,
                      })
                    "
                  >
                    <v-icon>mdi-image-outline</v-icon>
                  </v-btn>
                </div>
                <span class="mr-3 text-body-2">テストピース</span>
                <br v-if="$vuetify.breakpoint.xs" />
                <span
                  class="mr-3 text-caption"
                  v-for="testPiece in tabItem.testPieces"
                  :key="testPiece.name"
                  >{{ testPiece.name }}：φ {{ testPiece.size.toFixed(1) }} mm</span
                >
              </v-sheet>
              <v-data-table
                :headers="headers"
                :items="tabItem.items"
                :items-per-page="-1"
                :loading="loading"
                loading-text="読込中"
                no-data-text="右上の＋ボタンから製品を追加して点検してください"
                disable-sort
                fixed-header
                :hide-default-header="$vuetify.breakpoint.xs"
                hide-default-footer
                dense
                :height="getSheetHeight - ($vuetify.breakpoint.xs ? 94 : 70)"
              >
                <template #item="{ item, index }">
                  <!-- 横スマホ・タブレット・PC -->
                  <tr
                    v-if="$vuetify.breakpoint.smAndUp"
                    :class="isNormalResult(item) ? 'white' : 'red lighten-5'"
                  >
                    <td style="min-width: 150px">{{ item.productName }}</td>
                    <td>
                      <v-btn-toggle v-model="item.timing" dense color="primary">
                        <v-btn value="開始" small>開始</v-btn>
                        <v-btn value="経過" small>経過</v-btn>
                        <v-btn value="終了" small>終了</v-btn>
                      </v-btn-toggle>
                    </td>
                    <td>
                      <div class="d-flex justify-end justify-sm-center" style="min-width: 168px">
                        <div class="mr-2 d-flex flex-column">
                          <div
                            class="d-flex align-center"
                            v-for="(testPiece, i) in item.testPieces"
                            :key="i"
                            style="height: 36px"
                          >
                            {{ testPiece.testPieceName }}
                          </div>
                        </div>
                        <div class="d-flex flex-column">
                          <div class="py-1" v-for="(testPiece, i) in item.testPieces" :key="i">
                            <ButtonCheckResult
                              :clearable="false"
                              :disabled="false"
                              :value="testPiece.checkResult"
                              @input="testPiece.checkResult = $event"
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <ButtonCheckResult
                        :clearable="false"
                        :disabled="false"
                        :value="item.detectionResult"
                        @input="item.detectionResult = $event"
                      />
                    </td>
                    <td>
                      <div class="d-flex">
                        <MenuComment :item="item" targetKey="productName" />
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ml-2" v-bind="attrs" v-on="on" icon>
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-card class="py-2">
                            <v-btn text @click="tabItem.items.splice(index, 1)">
                              <v-icon>mdi-trash-can-outline</v-icon>
                              <span class="ml-2">一覧から削除</span>
                            </v-btn>
                          </v-card>
                        </v-menu>
                      </div>
                    </td>
                  </tr>

                  <!-- 縦スマホ -->
                  <tr v-else :class="isNormalResult(item) ? 'white' : 'red lighten-5'">
                    <td class="px-4">
                      <div class="py-2 d-flex">
                        <div>
                          <p class="mb-0 grey--text" style="font-size: 0.5rem">製品名</p>
                          <p class="mb-2">{{ item.productName }}</p>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="d-flex">
                          <MenuComment :item="item" targetKey="productName" />
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="ml-2" v-bind="attrs" v-on="on" icon>
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            <v-card class="py-2">
                              <v-btn text @click="tabItem.items.splice(index, 1)">
                                <v-icon>mdi-trash-can-outline</v-icon>
                                <span class="ml-2">一覧から削除</span>
                              </v-btn>
                            </v-card>
                          </v-menu>
                        </div>
                      </div>
                      <div class="py-2 d-flex align-center">
                        <span class="text-caption text--secondary">点検タイミング</span>
                        <v-spacer></v-spacer>
                        <v-btn-toggle v-model="item.timing" dense color="primary">
                          <v-btn value="開始" small>開始</v-btn>
                          <v-btn value="経過" small>経過</v-btn>
                          <v-btn value="終了" small>終了</v-btn>
                        </v-btn-toggle>
                      </div>
                      <div class="py-2 d-flex align-center">
                        <span class="text-caption text--secondary">作動確認</span>
                        <v-spacer></v-spacer>
                        <div class="mr-2 d-flex flex-column">
                          <div
                            class="d-flex align-center text-caption"
                            v-for="(testPiece, i) in item.testPieces"
                            :key="i"
                            style="height: 36px"
                          >
                            {{ testPiece.testPieceName }}
                          </div>
                        </div>
                        <div class="d-flex flex-column">
                          <div class="py-1" v-for="(testPiece, i) in item.testPieces" :key="i">
                            <ButtonCheckResult
                              :clearable="false"
                              :disabled="false"
                              :value="testPiece.checkResult"
                              @input="testPiece.checkResult = $event"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="py-2 d-flex align-center">
                        <span class="text-caption text--secondary">検出物なし</span>
                        <v-spacer></v-spacer>
                        <ButtonCheckResult
                          :clearable="false"
                          :disabled="false"
                          :value="item.detectionResult"
                          @input="item.detectionResult = $event"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- 特記事項・送信ボタン -->
    <div class="d-sm-flex align-center">
      <CardComments
        ref="comments"
        :date="businessDate"
        :isMonthly="false"
        serviceName="異物探知"
        @load="existsComment = $event.length > 0"
      />
      <ButtonSendResult
        :loading="sending"
        :isNormal="tabItems[tab] ? tabItems[tab].items.every((item) => isNormalResult(item)) : true"
        :disabled="sending || isDisabledButton"
        @click="sendCheckResult()"
      />
    </div>

    <DialogImg ref="dialogImg" />
    <DialogSendError ref="sendErrorDialog" />
    <DialogMessage
      :dialog="messageDialog"
      :message="message"
      :messageText="messageText"
      @close="messageDialog = false"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import firebase from "../../plugins/firebase";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import uploadStorage from "cumin-common/src/mixins/uploadStorage";
import MenuProduct from "../organisms/MenuProduct";
import TotalResultDialog from "../organisms/TotalResultDialog";

export default {
  components: {
    MenuProduct,
    TotalResultDialog,
  },
  mixins: [calcDate, dbProcess, uploadStorage],
  data: () => ({
    timerID: null,
    loading: false,
    businessDate: "",
    tab: 0,
    detectors: [],
    products: [],
    tabItems: [],
    headers: [
      { text: "製品名", value: "productName", width: "70%" },
      { text: "点検タイミング", value: "timing", width: 166, align: "center" },
      { text: "作動確認", value: "testPieces", width: "30%", align: "center" },
      { text: "検出物なし", value: "detectionResult", width: 116, align: "center" },
      { value: "action", width: 96 },
    ],
    checkResults: [],
    detectorResults: [],
    existsComment: false,
    message: "",
    messageText: "",
    messageDialog: false,
    sending: false,
  }),
  created: async function () {
    this.$emit("created");
    this.logEvent("app_connect");

    this.businessDate = this.calculateBusinessDate(new Date());
  },
  activated: async function () {
    await this.loadList();
    await this.loadResults();
    this.createSummary();
    this.tab = 0;

    const setDate = () => (this.businessDate = this.calculateBusinessDate(new Date()));
    this.timerID = setInterval(setDate, 60000);
  },
  deactivated: function () {
    clearInterval(this.timerID);
  },
  computed: {
    /**
     * 点検票エリアの高さを取得
     * @return {number} 高さ
     */
    getSheetHeight() {
      const bp = this.$vuetify.breakpoint;
      const offset = this.existsComment ? 186 : 60;
      const exsitsRemarks = !!this.tabItems[this.tab]?.remarks;
      const height = bp.height - (bp.xs ? 222 : 212) - offset - (exsitsRemarks ? 36 : 0);
      return height <= 370 ? 370 : height;
    },

    /**
     * 結果判定
     * @param {object} 製品情報
     * @return {boolean} 判定結果
     */
    isNormalResult() {
      return (product) =>
        product.detectionResult != "NG" && product.testPieces.every((e) => e.checkResult != "NG");
    },

    /**
     * 送信ボタンの活性判定
     * @return {boolean} 判定結果
     */
    isDisabledButton() {
      if (this.tabItems.length == 0) return true;
      if (this.tabItems[this.tab].items.length == 0) return true;
      return this.tabItems[this.tab].items.some((e) => !e.timing);
    },
  },
  methods: {
    /**
     * DB読み込み
     */
    async loadList() {
      const shop = this.$store.getters.getShop;

      this.tabItems = [];

      this.detectors = await this.getQueryDoc({
        collection: "metalDetectors",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "machineName", directionStr: "asc" }],
      });

      this.products = await this.getQueryDoc({
        collection: "metalDetectorControlProducts",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "productName", directionStr: "asc" }],
      });

      this.tabItems = this.detectors.map((detector) => ({ ...detector, items: [] }));
    },

    /**
     * 点検結果の読み込み
     */
    async loadResults() {
      const shop = this.$store.getters.getShop;
      const startAt = new Date(this.businessDate + " 00:00:00");
      const endAt = new Date(this.businessDate + " 23:59:59");

      this.checkResults = await this.getQueryDoc({
        collection: "metalDetectorCheckResults",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "registeredAt", directionStr: "asc" }],
        startAt: startAt,
        endAt: endAt,
      });
    },

    /**
     * 点検回数の集計
     */
    async createSummary() {
      const timings = [
        { text: "開始", key: "start" },
        { text: "経過", key: "elapsed" },
        { text: "終了", key: "end" },
      ];

      this.detectorResults = this.detectors.map((d) => {
        const productsResults = this.products.map((p) => {
          const result = { start: 0, elapsed: 0, end: 0 };
          for (const item of this.checkResults) {
            if (item.productName != p.productName) continue;
            if (item.machineName != d.machineName) continue;
            for (const timing of timings) if (item.timing == timing.text) result[timing.key]++;
          }
          return { ...p, ...result };
        });
        return { ...d, results: productsResults };
      });
    },

    /**
     * リストから選択した製品をテーブルに追加
     * @param {array} selectedItems 選択した製品
     */
    async addToList(selectedItems) {
      const products = JSON.parse(JSON.stringify(this.tabItems[this.tab]));
      const testPieces = [];
      for (const testPiece of this.tabItems[this.tab].testPieces) {
        testPieces.push({
          testPieceName: testPiece.name,
          testPieceSize: testPiece.size,
          checkResult: "OK",
        });
      }

      for (const selectedItem of selectedItems) {
        if (!products.items.some((e) => e.productName == selectedItem.productName)) {
          products.items.push({
            productName: selectedItem.productName,
            timing: "",
            testPieces: JSON.parse(JSON.stringify(testPieces)),
            detectionResult: "OK",
          });
        }
      }
      this.$set(this.tabItems, this.tab, products);
    },

    /**
     * 点検結果送信
     */
    async sendCheckResult() {
      // オフライン時の処理
      if (!navigator.onLine) return this.$refs.sendErrorDialog.open("offline");

      this.messageText = "";
      this.sending = true;

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const sendDate = new Date(this.businessDate + " 00:00:00");
      const registeredAt = firebase.firestore.Timestamp.fromDate(sendDate);
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      // 点検結果をループ
      const ngItems = [];
      const sentIds = [];
      const writeItems = [];
      for (const product of this.tabItems[this.tab].items) {
        const resultId = this.createDocId("metalDetectorCheckResults");
        const sendData = {
          productName: product.productName,
          userUID: user.userUID,
          shopUID: shop.shopUID,
          machineName: this.tabItems[this.tab].machineName,
          timing: product.timing,
          checkItems: product.testPieces,
          detectionResult: product.detectionResult,
          isNormalForReport: true,
          registeredAt,
          sentAt: serverTimestamp,
          sender: user.name,
          confirmedAt: "",
          confirmerName: "",
          approvedAt: "",
          approverName: "",
          createdAt: serverTimestamp,
          updatedAt: serverTimestamp,
        };

        // NG項目があるか確認
        if (
          product.testPieces.some((e) => e.checkResult == "NG") ||
          product.detectionResult == "NG"
        ) {
          sendData.isNormalForReport = false;
          ngItems.push(product);
        }

        writeItems.push({
          method: "set",
          collection: "metalDetectorCheckResults",
          docId: resultId,
          data: sendData,
        });

        // 特記事項がある場合
        if (product.comment || product.imgFileUrl) {
          const commentId = this.createDocId("comments");
          const sendCommentData = {
            name: user.name,
            registrantUID: user.userUID,
            shopUID: shop.shopUID,
            position: user.position,
            content: product.comment || "",
            serviceName: "異物探知",
            relationCheckResult: {
              uid: resultId,
              title: sendData.productName,
              isNormal: sendData.isNormalForReport,
              sentAt: moment().format("MM/DD HH:mm"),
            },
            registeredAt,
            createdAt: serverTimestamp,
            updatedAt: serverTimestamp,
          };

          // 画像ありの場合、画像をアップロードする
          if (product.compressedFile) {
            const commentDate = this.businessDate.replace(/\//g, "-");
            const path = `commentsFile/${shop.shopUID}/${commentDate}/${commentId}.jpg`;
            const uploadResult = await this.uploadFile({ path, file: product.compressedFile });

            // 送信失敗の場合
            if (uploadResult.status == "error") {
              const type =
                uploadResult.code === "storage/retry-limit-exceeded" ? "unstable" : "unexpected";
              this.$refs.sendErrorDialog.open(type);
              this.sending = false;
              return;
            }

            sendCommentData.imgFileURL = uploadResult.url;
          }

          writeItems.push({
            method: "set",
            collection: "comments",
            docId: commentId,
            data: sendCommentData,
          });
        }
        sentIds.push(resultId);
      }

      // DB登録
      const result = await this.writeTransaction(writeItems);

      // 登録失敗の場合
      if (result.status !== "success") {
        const type = result.message == "Connection failed." ? "unstable" : "unexpected";
        this.$refs.sendErrorDialog.open(type);
        this.logEvent("send_check_list", { message: "点検票の送信に失敗しました。" });
        this.sending = false;
        return;
      }

      // 特記事項リストの更新
      const isSentComment = this.tabItems[this.tab].items.some(
        (e) => !!e.comment || !!e.compressedFile
      );
      if (isSentComment) await this.$refs.comments.loadComment();

      // 点検票リストの更新
      this.tabItems[this.tab].items = [];

      // 集計結果の更新
      await this.loadResults();
      this.createSummary();

      this.message = "送信しました。";
      this.messageText = "点検数：" + sentIds.length;
      this.messageDialog = true;
      this.logEvent("send_check_list", { message: this.message + this.messageText });

      this.sending = false;

      // 点検結果にNGがあった場合、メール送信
      if (ngItems.length > 0) this.sendNgMail(ngItems);
    },

    /**
     * 点検結果にNGがあった場合、メール送信
     * @param {array} items NG点検結果
     */
    async sendNgMail(items) {
      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;

      // 本文に記載する清掃場所一覧の作成
      let listText = "";
      for (const item of items) {
        listText = listText + "　" + item.productName + "　";
        listText = listText + "探知機名：" + this.tabItems[this.tab].machineName + "\n";
      }

      let environment = "";
      if (process.env.NODE_ENV == "development") environment = "[DEV]";
      if (process.env.NODE_ENV == "staging") environment = "[STG]";

      const functions = await firebase.app().functions("asia-northeast1");
      const sendMail = functions.httpsCallable("sendMail");

      const subject =
        shop.shopUID == this.$route.params.shopUID
          ? `${environment}[ハレコード] 異物探知点検結果NGのお知らせ`
          : `${environment}[ハレコード][${shop.selectShopName}] 異物探知点検結果NGのお知らせ`;

      await sendMail({
        domain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        position: user.position,
        shopUID: shop.shopUID,
        subject: subject,
        text:
          "各位\n\n" +
          "異物探知点検の点検結果にNGがありましたのでお知らせいたします。\n\n" +
          "【店舗名】\n" +
          "　" +
          `${shop.selectShopName}\n\n` +
          "【点検結果】\n" +
          `${listText}\n\n` +
          "以下のURLからログインして内容のご確認をお願いいたします。\n" +
          `${window.location.origin}/${shop.shopUID}/check-summary\n\n` +
          "※このメールは送信専用のため、ご返信いただけません。\n\n\n" +
          "－－－－－－－－－－－－－－－－－－－－－－－－－－－－－－\n" +
          "ハレコードは株式会社ウエノフードテクノの商標です。\n",
      });
    },
  },
};
</script>

<style scoped>
::v-deep .v-tabs-bar {
  height: 38px;
  background-color: transparent !important;
  border-bottom: solid 1px #0000001f !important;
}

::v-deep .v-slide-group__prev,
::v-deep .v-slide-group__next {
  min-width: 38px !important;
}

::v-deep .v-tabs-items {
  background-color: transparent;
}

::v-deep .v-window {
  overflow: initial;
}

::v-deep .v-data-table .v-data-table__wrapper table .v-data-table-header tr th {
  height: 48px !important;
  background-color: #f8f8f8 !important;
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper table thead tr th:first-child,
::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 16px !important;
}

::v-deep .v-data-table__wrapper table thead tr th:last-child,
::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 16px !important;
}

.v-data-table:not(.v-data-table--mobile) ::v-deep .v-data-table__wrapper table tbody tr {
  height: 42px !important;
}
.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row {
  min-height: 32px;
  font-size: 12px;
}
.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row__header {
  font-weight: 400;
}
</style>
