<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters :style="{ height: $vuetify.breakpoint.xs ? '76px' : '60px' }">
      <v-col cols="12" sm="4" align-self="center">
        <span class="text-subtitle-1 text-sm-h6">点検結果一覧</span>
      </v-col>
      <v-col cols="9" sm="5" align-self="center">
        <DatePicker :date.sync="selectedDate" @pick="updateDate" />
      </v-col>
      <v-col class="text-end" cols="3" align-self="center">
        <TotalResultDialog :items="detectorResults" :detectors="detectors" :date="selectedDate" />
        <DialogExportResult
          serviceName="異物探知"
          :properties="exportProperties"
          :selectedDate="selectedDate"
        />
      </v-col>
    </v-row>

    <!-- 点検結果一覧 -->
    <v-row no-gutters class="pb-4">
      <v-col>
        <TableSummary
          :selected.sync="selected"
          :items="items"
          :headers="$vuetify.breakpoint.xs ? xsHeaders : headers"
          :filterHeader="filterHeader"
          :comments="comments"
          :loading="loading"
          @clickRow="$refs.checkResultDialog.openDialog($event)"
        />
      </v-col>
    </v-row>

    <!-- 特記事項・送信ボタン -->
    <div class="d-sm-flex align-center">
      <CardComments
        ref="comments"
        :date="selectedDate"
        :isMonthly="selectedDate.length == 7"
        serviceName="異物探知"
        @load="comments = $event"
      />
      <ButtonApproveResult
        :items="selected"
        :disabled="selected.length == 0"
        collection="metalDetectorCheckResults"
        @reload="loadList()"
      />
    </div>

    <DialogCheckResult
      ref="checkResultDialog"
      :comments="comments"
      @update="$refs.comments.loadComment()"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import DialogCheckResult from "../organisms/DialogCheckResult";
import TotalResultDialog from "../organisms/TotalResultDialog";

export default {
  components: {
    DialogCheckResult,
    TotalResultDialog,
  },
  mixins: [calcDate, dbProcess],
  data: () => ({
    loading: false,
    selectedDate: "",
    detectors: [],
    products: [],
    selected: [],
    items: [],
    headers: [
      { text: "製品名", value: "productName", sortable: false, width: 150, class: "responsive" },
      { text: "探知機", value: "machineName", sortable: false, width: 150, class: "responsive" },
      { text: "タイミング", value: "timing", width: 100 },
      { text: "点検結果", value: "isNormalForReport", width: 82 },
      { text: "送信日時", value: "sentAt", width: 100 },
      { text: "確認日時", value: "confirmedAt", width: 100 },
      { text: "承認日時", value: "approvedAt", width: 112 },
    ],
    xsHeaders: [
      { text: "製品名", value: "productName", sortable: false, width: 150, class: "responsive" },
      { text: "探知機", value: "machineName", sortable: false, width: 150, class: "responsive" },
      { text: "タイミング", value: "timing", width: 100 },
      { text: "結果", value: "isNormalForReport", width: 58 },
      { text: "送信", value: "sentAt", align: "center", width: 44 },
      { text: "確認", value: "confirmedAt", align: "center", width: 34 },
      { text: "承認", value: "approvedAt", align: "center", width: 46 },
    ],
    filterHeader: { productName: "", machineName: "" },
    detectorResults: [],
    comments: [],
    exportProperties: [
      {
        sheetName: "異物探知点検結果",
        collection: "metalDetectorCheckResults",
        resultKey: "checkItems",
        resultColumns: [
          { header: "テストピース名", key: "testPieceName" },
          { header: "テストピースサイズ", key: "testPieceSize" },
          { header: "作動確認", key: "checkResult" },
        ],
        columns: [
          { header: "製品名", key: "productName" },
          { header: "探知機", key: "machineName" },
          { header: "タイミング", key: "timing" },
          { header: "点検結果", key: "checkItems" },
          { header: "検出物なし", key: "detectionResult" },
          { header: "送信日時", key: "sentAt" },
          { header: "送信者", key: "sender" },
          { header: "確認日時", key: "confirmedAt" },
          { header: "確認者", key: "confirmerName" },
          { header: "承認日時", key: "approvedAt" },
          { header: "承認者", key: "approverName" },
        ],
      },
    ],
  }),
  created: async function () {
    this.$emit("created");
    const queryDate = this.$route.query.date;
    this.selectedDate = queryDate ? queryDate : this.calculateBusinessDate(new Date());
  },
  activated: async function () {
    await this.loadData();
    await this.loadList();
    this.createSummary();
  },
  methods: {
    /**
     * DB読み込み
     */
    async loadData() {
      const shop = this.$store.getters.getShop;

      this.detectors = await this.getQueryDoc({
        collection: "metalDetectors",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "machineName", directionStr: "asc" }],
      });

      this.products = await this.getQueryDoc({
        collection: "metalDetectorControlProducts",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "productName", directionStr: "asc" }],
      });
    },

    /**
     * DBから点検結果一覧を取得
     */
    async loadList() {
      this.loading = true;
      const shop = this.$store.getters.getShop;
      const isMonthly = this.selectedDate.length == 7;

      const startAt = isMonthly
        ? new Date(`${this.selectedDate}/01 00:00:00`)
        : new Date(`${this.selectedDate} 00:00:00`);
      const endAt = isMonthly
        ? new Date(moment(startAt).add(1, "month").add(-1, "seconds"))
        : new Date(`${this.selectedDate} 23:59:59`);

      this.items = await this.getQueryDoc({
        collection: "metalDetectorCheckResults",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "registeredAt", directionStr: "asc" }],
        startAt: startAt,
        endAt: endAt,
      });

      // 送信日時で昇順にソート
      this.items.sort((a, b) => {
        if (a.sentAt.seconds > b.sentAt.seconds) return 1;
        if (a.sentAt.seconds < b.sentAt.seconds) return -1;
        return 0;
      });

      // 機械名で昇順にソート
      this.items.sort((a, b) => {
        if (a.machineName > b.machineName) return 1;
        if (a.machineName < b.machineName) return -1;
        return 0;
      });

      // 製品名で昇順にソート
      this.items.sort((a, b) => {
        if (a.productName > b.productName) return 1;
        if (a.productName < b.productName) return -1;
        return 0;
      });

      this.loading = false;
      this.selected = [];
    },

    /**
     * 点検回数の集計
     */
    async createSummary() {
      const timings = [
        { text: "開始", key: "start" },
        { text: "経過", key: "elapsed" },
        { text: "終了", key: "end" },
      ];

      this.detectorResults = this.detectors.map((d) => {
        const productsResults = this.products.map((p) => {
          const result = { start: 0, elapsed: 0, end: 0 };
          for (const item of this.items) {
            if (item.productName != p.productName) continue;
            if (item.machineName != d.machineName) continue;
            for (const timing of timings) if (item.timing == timing.text) result[timing.key]++;
          }
          return { ...p, ...result };
        });
        return { ...d, results: productsResults };
      });
    },

    /**
     * 日付変更があったら点検結果再読み込み
     * @param {string} date 日時
     */
    async updateDate(date) {
      this.selectedDate = date;
      await this.loadList();
      this.createSummary();
      this.$nextTick(() => this.$refs.comments.loadComment());
    },
  },
};
</script>
