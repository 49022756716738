<template>
  <v-dialog v-model="dialog" persistent max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="white"
        v-bind="attrs"
        v-on="on"
        color="primary"
        icon
        elevation="2"
        :large="$vuetify.breakpoint.smAndUp"
      >
        <v-icon>mdi-table-eye</v-icon>
      </v-btn>
    </template>
    <v-card class="pa-2 pa-sm-4" color="#f5f5f5">
      <v-card-title class="pa-4 d-flex align-baseline">
        <span class="mr-5 text-subtitle-1 text-sm-h6">点検状況確認</span>
        <span class="text-caption text-sm-subtitle-1 text--secondary">{{ date }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text v-if="items.length == 0" class="px-4 pb-0" style="height: 50vh">
        探知機情報がありません
      </v-card-text>

      <!-- タブ部分 -->
      <v-tabs v-else v-model="tab" center-active show-arrows>
        <v-tab v-for="machine in items" :key="machine.machineName">
          {{ machine.machineName }}
        </v-tab>
      </v-tabs>

      <!-- タブ内容 -->
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="machine in items" :key="machine.machineName">
          <v-card class="pa-2" outlined>
            <div class="px-2 pt-2">
              <div v-if="machine.remarks || machine.imgFileURL" class="d-flex align-center">
                <span class="text-caption text--secondary" style="white-space: pre-wrap">{{
                  machine.remarks
                }}</span>
                <v-btn
                  v-if="machine.imgFileURL"
                  icon
                  @click.stop="
                    $refs.dialogImg.openDialog({
                      text: `機械名：${machine.machineName}\n備考：${machine.remarks}`,
                      url: machine.imgFileURL,
                    })
                  "
                >
                  <v-icon>mdi-image-outline</v-icon>
                </v-btn>
              </div>
              <span class="mr-3 text-body-2">テストピース</span>
              <br v-if="$vuetify.breakpoint.xs" />
              <span
                class="mr-3 text-caption"
                v-for="testPiece in detectors[tab].testPieces"
                :key="testPiece.name"
              >
                {{ testPiece.name }}：φ {{ testPiece.size.toFixed(1) }} mm
              </span>
            </div>
            <v-data-table
              :headers="$vuetify.breakpoint.xs ? xsHeaders : headers"
              :items="machine.results"
              :items-per-page="-1"
              sort-by="productName"
              no-data-text="データがありません。"
              fixed-header
              hide-default-footer
              :mobile-breakpoint="null"
              dense
              height="50vh"
            >
              <template #[`item.productName`]="{ item }">
                <div style="min-width: 150px">{{ item.productName }}</div>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <DialogImg ref="dialogImg" />
  </v-dialog>
</template>

<script>
export default {
  props: {
    items: Array,
    detectors: Array,
    date: String,
  },
  data: () => ({
    dialog: false,
    tab: 0,
    headers: [
      { text: "製品名", value: "productName" },
      { text: "開始", value: "start", align: "center", width: 76, class: "pl-6" },
      { text: "経過", value: "elapsed", align: "center", width: 76, class: "pl-6" },
      { text: "終了", value: "end", align: "center", width: 76, class: "pl-6" },
    ],
    xsHeaders: [
      { text: "製品名", value: "productName" },
      { text: "開始", value: "start", align: "center", width: 58 },
      { text: "経過", value: "elapsed", align: "center", width: 58 },
      { text: "終了", value: "end", align: "center", width: 58 },
    ],
  }),
};
</script>

<style scoped>
::v-deep .v-tabs-bar {
  height: 38px;
  background-color: transparent !important;
  border-bottom: solid 1px #0000001f !important;
}

::v-deep .v-tabs-items {
  background-color: transparent;
}

::v-deep .v-window {
  overflow: initial;
}

::v-deep .v-slide-group__prev,
::v-deep .v-slide-group__next {
  min-width: 37px !important;
}

::v-deep .v-data-table .v-data-table__wrapper table thead tr th {
  height: 48px !important;
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
}
</style>
