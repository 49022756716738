/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import firebase from "../plugins/firebase";
import store from "../store/index";
import axios from "axios";
import VueRouter, { RouteConfig } from "vue-router";
import logEvent from "cumin-common/src/mixins/logEvent";
import CheckSheet from "../components/pages/CheckSheet.vue";
import CheckSummary from "../components/pages/CheckSummary.vue";
import ProductsMaster from "../components/pages/ProductsMaster.vue";
import DetectorMaster from "../components/pages/DetectorMaster.vue";
import PageSystemError from "cumin-common/src/components/pages/PageSystemError.vue";
import PageNotFound from "cumin-common/src/components/pages/PageNotFound.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    meta: { requiresAuth: true },
    path: "/:shopUID/check-sheet",
    name: "CheckSheet",
    component: CheckSheet,
  },
  {
    meta: { requiresAuth: true },
    path: "/:shopUID/check-summary",
    name: "CheckSummary",
    component: CheckSummary,
  },
  {
    meta: { requiresAuth: true },
    path: "/:shopUID/products-master",
    name: "ProductsMaster",
    component: ProductsMaster,
  },
  {
    meta: { requiresAuth: true },
    path: "/:shopUID/detector-master",
    name: "DetectorMaster",
    component: DetectorMaster,
  },
  {
    path: "*",
    name: "NotFound",
    component: PageNotFound,
  },
  {
    path: "*",
    name: "SystemError",
    component: PageSystemError,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/**
 * ログインユーザー状態の取得
 * @return ユーザー状態
 */
function getAuthUser(): any {
  return new Promise((resolve) => {
    firebase.auth().onAuthStateChanged((user) => {
      resolve(user ? user : null);
    });
  });
}

/**
 * ユーザー権限が十分であるか確認
 * @param {string} auth
 * @param {string} path
 * @return {boolean} ルーティングの許可判定
 */
function isFullyAuth(auth: string, path: string): boolean {
  if (auth == "admin") return false;
  if (path.includes("/check-sheet")) return true;
  if (path.includes("/check-summary")) return true;
  if (path.includes("/products-master")) return auth != "general";
  if (path.includes("/detector-master")) return auth != "general";
  return false;
}

/**
 * すでにログイン済の場合、認証済であるか判定
 * @param {string} auth
 * @param {string} path
 * @param {string} serviceName
 * @return {object} クレイム情報
 */
async function isAuthenticatedUser(authUser: any, path: string, serviceName: string): Promise<any> {
  // サービス利用状況の確認
  const shop = store.getters.getShop;
  if (!shop[serviceName]) return { claims: null };

  // カスタムクレイム情報を取得
  const getIdTokenResult = await authUser.getIdTokenResult(true).catch((error: any) => {
    console.log(error);
    return { claims: null, method: "getIdTokenResult", error };
  });

  // 取得エラーの場合
  if (getIdTokenResult.claims == null) return getIdTokenResult;

  const claims = getIdTokenResult.claims;
  if (!claims.codeVerified) return { claims: null };

  // 権限が十分であるか確認
  if (isFullyAuth(claims.user.authority, path)) {
    store.commit("setUserClaims", claims.user);
    return { claims };
  }
  return { claims: null };
}

/**
 * 未ログインの場合、セッションcookieで再認証
 * @param {string} path
 * @param {string} serviceName
 * @return {object} クレイム情報
 */
async function sessionExists(path: string, serviceName: string): Promise<any> {
  // functions関数の呼び出し
  const response: any = await axios.get("/session-login").catch((error) => {
    console.log(error);
    return { data: { status: "error", method: "session-login", error } };
  });

  // レスポンスがエラーの場合
  if (response.data.status == "error") {
    return {
      claims: null,
      method: response.data.method,
      error: response.data.error,
    };
  }

  // カスタムトークンが取得できない場合
  if (response.data.token == undefined) return { claims: null };

  // クレイム情報を取得
  const claims = response.data.claims;

  // サービス利用状況の確認
  if (!claims.shop[serviceName]) return { claims: null };

  // 権限が十分であるか確認
  if (!isFullyAuth(claims.user.authority, path)) return { claims: null };

  // カスタムトークンで認証
  return await firebase
    .auth()
    .signInWithCustomToken(response.data.token)
    .then(() => {
      store.commit("setShopInfo", claims.shop);
      store.commit("setUserClaims", claims.user);
      return { claims };
    })
    .catch((error) => {
      console.log(error);
      return { claims: null, method: "signInWithCustomToken", error };
    });
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (location.origin.match("localhost")) return next();
    store.commit("clearUserData");
    const loginURL = process.env.VUE_APP_DIAMOND_URL + "/" + to.params.shopUID;
    const serviceName = "inspection";

    // 認証されているユーザー情報を取得
    const authUser = await getAuthUser();
    const result =
      authUser == null
        ? await sessionExists(to.path, serviceName)
        : await isAuthenticatedUser(authUser, to.path, serviceName);

    // 認証処理エラーの場合
    if (result.error != undefined) {
      logEvent.methods.logEvent("error_auth", {
        method_name: result.method,
        error_message: result.error.message,
      });
      return next({
        name: "SystemError",
        params: {
          0: to.path,
          shopUID: to.params.shopUID,
          error: result.error.message,
        },
        replace: true,
      });
    }

    // 未認証の場合
    if (result.claims == null) {
      window.location.href = loginURL;
      return next(false);
    }

    // URLのUIDとログイン情報を確認
    if (result.claims.shop.headquartersUID == to.params.shopUID) return next();
    if (result.claims.shop.shopUID == to.params.shopUID) return next();

    // URLのUIDがログイン情報と違う場合
    next({
      name: "NotFound",
      params: { 0: to.path },
      replace: true,
    });
  } else {
    next();
  }
});

export default router;
